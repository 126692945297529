<template>
  <div class="list" v-if="show">
    <div
      class="list-item cursor-pointer"
      v-for="(i, k) in list"
      :key="k"
      @click.stop.prevent="goDetail(i)"
    >
      <div class="item-img">
        <img :src="i.cover" />
        <div class="item-img-bg">
          <span class="label">ACTIVITYD</span>
          <div
            :class="`status ${
              i.status_txt == '进行中'
                ? 'red'
                : i.status_txt == '已结束'
                ? 'grey'
                : i.status_txt == '未开始'
                ? 'blue'
                : ''
            }`"
          >
            <span>{{ i.status_txt }}</span>
          </div>
        </div>
      </div>
      <div class="down">
        <p class="item-title">{{ i.title }}</p>
        <div class="time">
          <div class="icon-time"></div>
          <span>{{ i.start_time.substring(0, 16) }}</span>
          <span>--</span>
          <span>{{ i.end_time.substring(0, 16) }}</span>
        </div>
        <div class="spot" @click.stop.prevent="spotClick(k)">
          <div class="icon"></div>
        </div>
        <div
          :class="`activity-nav ${activityIndexNav == k ? 'active' : ''}`"
          @mouseleave="activityIndexNavReset"
        >
          <div
            class="nav-item cursor-pointer"
            @click.stop.prevent="activityEdit(i, k)"
          >
            <div class="icon-edit"></div>
            <span>编辑活动</span>
          </div>
          <div
            class="nav-item cursor-pointer"
            @click.stop.prevent="activityStatistics(i, k)"
          >
            <div class="icon-edit"></div>
            <span>数据统计</span>
          </div>
          <div
            :class="`nav-item cursor-pointer ${
              i.status_txt == '已结束' ? 'non-active' : ''
            }`"
            @click.stop.prevent="activityOver(i, k)"
          >
            <div class="icon-over"></div>
            <span>结束活动</span>
          </div>
        </div>
      </div>
    </div>
    <list-empty
      :show="list.length == 0"
      tab="暂无活动"
      :full="true"
    ></list-empty>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { routesModuleData, routesConvert } from "@/router/index";
import listEmpty from "@/components/common/ListEmpty.vue";
export default {
  components: { listEmpty },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 999,
      list: [],
      activityIndexNav: -1,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  watch: {
    show: {
      handler: function (newVal) {
        if (newVal) {
          this.getList();
        } else {
          this.list = [];
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    activityOver(i) {
      this.activityIndexNavReset();
      if (i.status_txt == "已结束") {
        this.$toast.fail("该活动已结束");
        return;
      }
      this.$dialog
        .confirm({
          title: "提示",
          message: "确定要结束该活动吗？",
        })
        .then(async () => {
          this.$toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: "操作中...",
          });
          const result = await this.$http.post("/activity/close", {
            id: i.id,
          });
          this.activityIndexNavReset();
          if (result.code == 200) {
            this.$toast.success("操作成功");
            this.getList(true);
          } else {
            this.$toast.fail("操作失败：" + result.msg);
          }
        })
        .catch(() => {
          console.log("取消");
        });
    },
    spotClick(k) {
      this.activityIndexNav = k;
    },
    activityIndexNavReset() {
      this.activityIndexNav = -1;
    },
    async activityEdit(item) {
      this.activityIndexNavReset();
      this.$router.push({
        name: routesConvert(routesModuleData.activityCreate, true).name,
        query: { id: item.id },
      });
    },
    activityStatistics(item) {
      this.activityIndexNavReset();
      console.log(item);
      // this.$toast("敬请期待...");
      this.$router.push({
        name: routesConvert(routesModuleData.statistics, true).name,
        query: { id: item.id },
      });
    },
    goDetail(item) {
      if (item.status_txt == "已结束") {
        this.$toast.fail("活动已结束");
        return;
      }
      if (sessionStorage.getItem("isWxMini") == 1 && !this.userInfo) {
        parent.wx.miniProgram.reLaunch({
          url: `/subpackage_login_cy/game/game?id=${item.id}${
            process.env.NODE_ENV == "development" ? "_1" : "_"
          }&al=1${this.userInfo ? "&skip=1" : ""}`,
        });
      } else {
        this.$router.push({
          name: routesConvert(routesModuleData.detail, true).name,
          query: { id: item.id },
        });
      }
    },
    async getList(noToast) {
      if (!noToast) {
        this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: "加载中...",
        });
      }
      const result = await this.$http.get("/activity/list", {
        page: this.currentPage,
        per_page: this.perPage,
      });
      if (result.code == 200) {
        this.$toast.clear();
        if (result.data.data && result.data.data.length > 0) {
          if (this.currentPage === 1) {
            this.list = [...result.data.data];
          } else {
            this.list = [...this.list, ...result.data.data];
          }
        } else {
          this.list = [];
        }
      } else {
        this.$toast.fail("加载失败：" + result.msg);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.list {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  .list-item {
    width: 712px;
    height: 614px;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px 2px rgba(172, 174, 197, 59%);
    transition: all 0.3s ease-in-out;
    margin-bottom: 100px;
    margin-right: 60px;
    &:nth-child(4n) {
      margin-right: 0;
    }
    .item-img {
      flex-shrink: 0;
      width: 100%;
      height: 422px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
      .item-img-bg {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 70px;
        z-index: 1;
        border-top: 1px solid rgba(255, 239, 226, 0.2);
        border-radius: 20px 20px 0 0;
        background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0.1),
          rgba(111, 109, 109, 0.1)
        );
        box-shadow: 0px 20px 40px 4px rgba(24, 124, 177, 0.3);
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        padding-left: 42px;
        backdrop-filter: blur(6px);
        .label {
          font-size: 40px;
          font-family: Helvetica Black-Regular, Helvetica Black;
          font-weight: 400;
          line-height: 47px;
          text-shadow: 0px 16px 64px rgba(0, 0, 0, 0.16);
          background: linear-gradient(
            270deg,
            #4e54ff 13%,
            #21b4c5 51%,
            #1285d1 91%
          );
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .status {
          width: 180px;
          height: 76px;
          border-radius: 50px 0px 0px 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          &.red {
            background: linear-gradient(90deg, #f9a1a6 0%, #f55c64 100%);
          }
          &.grey {
            background: linear-gradient(270deg, #7f7d7d 0%, #d6d5d5 100%);
          }
          &.blue {
            background: linear-gradient(90deg, #7dc4f9 0%, #118eec 100%);
          }
          span {
            font-size: 30px;
            font-family: Source Han Sans CN-Normal, Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
    }
    .down {
      background: linear-gradient(
        45deg,
        rgba(255, 255, 255, 1),
        rgba(234, 247, 255, 1)
      );
      flex: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      box-sizing: border-box;
      padding: 32px 0 0 36px;
      position: relative;
      .activity-nav {
        position: absolute;
        top: 0;
        right: 20px;
        width: 320px;
        height: 309px;
        border-radius: 22px;
        background: linear-gradient(
          45deg,
          rgba(31, 39, 53, 1),
          rgba(51, 58, 72, 1)
        );
        visibility: hidden;
        opacity: 0;
        transition: all 0.4s ease-in-out;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        box-sizing: border-box;
        padding: 48px 0;
        z-index: 10;
        &.active {
          visibility: visible;
          top: -130px;
          // top: -40px;
          opacity: 1;
        }
        .nav-item {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          transition: all 0.3s ease-in-out;
          &:first-child {
            background: linear-gradient(
              90deg,
              rgba(68, 86, 176, 1),
              rgba(36, 60, 115, 0) 80%
            );
          }
          height: 64px;
          box-sizing: border-box;
          box-sizing: border-box;
          padding: 22px 54px 12px 54px;
          span {
            margin-left: 14px;
            font-size: 30px;
            font-family: Source Han Sans CN-Normal, Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
          }
          &.non-active {
            // span {
            //   color: #999999;
            // }
          }
          .icon-edit {
            flex-shrink: 0;
            width: 56px;
            height: 56px;
            background: url(#{$PublicEnv}/activity_my/icon_edit.png);
            background-size: 100% 100%;
          }
          .icon-over {
            flex-shrink: 0;
            width: 56px;
            height: 56px;
            background: url(#{$PublicEnv}/activity_my/icon_over.png);
            background-size: 100% 100%;
          }
        }
      }
      .spot {
        position: absolute;
        right: 0;
        bottom: 0;
        padding-right: 40px;
        padding-bottom: 30px;
        .icon {
          width: 54px;
          height: 14px;
          background: url(#{$PublicEnv}/activity_my/spot.png);
          background-size: 100% 100%;
        }
      }
      .item-title {
        font-size: 40px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #333333;
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
      }
      .time {
        width: 100%;
        margin-top: 14px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .icon-time {
          width: 48px;
          height: 48px;
          background: url(#{$PublicEnv}/index/icon_time.png);
          background-size: 100% 100%;
          margin-right: 20px;
          flex-shrink: 0;
        }
        span {
          font-size: 32px;
          font-family: DIN-Regular, DIN;
          font-weight: 400;
          color: #666666;
          &:nth-child(2) {
            margin: 0 6px;
          }
        }
      }
    }
  }
  .list-empty {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 600px;
    .empty-icon {
      width: 354px;
      height: 70px;
      background: url(#{$PublicEnv}/activity_my/my_activity_empty.png);
      background-size: 100% 100%;
    }
  }
}

@media (orientation: portrait) {
  .list {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    min-width: 3430px;
    .list-item {
      width: 3430px;
      height: 2879px;
      margin: 0;
      margin-bottom: 205px;
      border-radius: 120px;
      .item-img {
        width: 100%;
        height: 2060px;
        img {
        }
        .item-img-bg {
          height: 342px;
          box-sizing: border-box;
          padding-left: 179px;
          .label {
            font-size: 154px;
            font-family: Helvetica Black-Regular, Helvetica Black;
            font-weight: 400;
            line-height: 180px;
            text-shadow: 0px 41px 164px rgba(0, 0, 0, 0.16);
            background: linear-gradient(
              270deg,
              #4e54ff 13%,
              #21b4c5 51%,
              #1285d1 91%
            );
            background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          .status {
            width: 800px;
            height: 342px;
            border-radius: 205px 0px 0px 205px;
            &.red {
            }
            &.grey {
            }
            &.blue {
            }
            span {
              font-size: 133px;
            }
          }
        }
      }
      .down {
        height: 819px;
        padding: 154px 154px 0 154px;
        .activity-nav {
          width: 1367px;
          height: 1287px;
          padding: 220px 0;
          right: 120px;
          border-radius: 56px;
          &.active {
            top: -520px;
            // top: -260px;
          }
          .nav-item {
            height: 271px;
            justify-content: center;
            &:first-child {
              // margin-bottom: 60px;
            }
            span {
              font-size: 184px;
              margin-left: 0;
            }
            &.non-active {
            }
            .icon-edit {
              width: 200px;
              height: 200px;
              margin-right: 102px;
            }
            .icon-over {
              margin-right: 56px;
              width: 282px;
              height: 282px;
            }
          }
        }
        .spot {
          padding-right: 154px;
          padding-bottom: 230px;
          .icon {
            width: 240px;
            height: 63px;
          }
        }
        .item-title {
          font-size: 154px;
        }
        .time {
          margin-top: 54px;
          .icon-time {
            width: 213px;
            height: 207px;
            margin-right: 61px;
          }
          span {
            font-size: 132px;
            &:nth-child(2) {
            }
          }
        }
      }
    }
    .list-empty {
      flex: 1;
      .empty-icon {
        width: 906px;
        height: 179px;
      }
    }
  }
  .action-confirm-tips {
    .icon-upload {
      width: 420px;
      height: 420px;
    }
    .icon-upload-success {
      width: 420px;
      height: 420px;
      margin-bottom: 77px;
    }
    span {
      font-size: 184px;
      &.tips {
        font-size: 143px;
        margin-top: 128px;
      }
    }
  }
}
</style>
