<template>
  <div class="index">
    <pop-top :black="true" @go="locateChange"></pop-top>
    <div class="content">
      <div class="nav">
        <div
          :class="`nav-item cursor-pointer ${k == currentNav ? 'active' : ''}`"
          v-for="(i, k) in navList"
          :key="k"
          @click="navClick(i, k)"
        >
          <img :src="getIcon(i, k)" />
          <span>{{ i.name }}</span>
        </div>
      </div>
      <div class="info">
        <div class="title">
          <div class="left">
            <span>{{ navList[currentNav].title }}</span>
            <span v-if="!$utils.isMobile().mobile">{{
              navList[currentNav].titleEng
            }}</span>
          </div>
          <div
            :class="`right right-${navList[currentNav].value}`"
            v-if="navList[currentNav].show"
          >
            <div
              class="create-action cursor-pointer"
              @click.stop.prevent="doAction"
            >
              <span>{{ navList[currentNav].button }}</span>
            </div>
          </div>
        </div>
        <div class="container">
          <channel-list
            :show="navList[currentNav].value == 'channel'"
          ></channel-list>
          <activity-list
            :show="navList[currentNav].value == 'activity'"
          ></activity-list>
          <resource-list
            ref="resource"
            :show="navList[currentNav].value == 'resource'"
          ></resource-list>
          <nft-list
            ref="nft"
            :show="navList[currentNav].value == 'nft'"
          ></nft-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { routesModuleData, routesConvert, routesData } from "@/router/index";
import { wxShare } from "@/assets/js/wechatAuth.js";
import PopTop from "@/components/PopTop.vue";
import ActivityList from "@/components/manage/ActivityList.vue";
import ResourceList from "@/components/manage/ResourceList.vue";
import ChannelList from "@/components/manage/ChannelList.vue";
import NftList from "@/components/manage/NftList.vue";

export default {
  components: { PopTop, ActivityList, ResourceList, NftList, ChannelList },
  data() {
    return {
      href: location.href,
      currentNav: 0,
      navList: [
        {
          name: "活动管理",
          icon: this.$ossRes("/manage/icon_activity.png"),
          iconActive: this.$ossRes("/manage/icon_activity_active.png"),
          iconActiveMobile: this.$ossRes(
            "/manage/icon_activity_active_mobile.png"
          ),
          value: "activity",
          title: "我的活动",
          titleEng: "/ My Activity",
          button: "新建活动",
          show: true,
        },
        {
          name: "频道管理",
          icon: this.$ossRes("/manage/icon_activity.png"),
          iconActive: this.$ossRes("/manage/icon_activity_active.png"),
          iconActiveMobile: this.$ossRes(
            "/manage/icon_activity_active_mobile.png"
          ),
          value: "channel",
          title: "我的频道",
          titleEng: "/ My Channel",
          button: "新建频道",
          show: true,
        },
        {
          name: "资源管理",
          icon: this.$ossRes("/manage/icon_resource.png"),
          iconActive: this.$ossRes("/manage/icon_resource_active.png"),
          iconActiveMobile: this.$ossRes(
            "/manage/icon_resource_active_mobile.png"
          ),
          value: "resource",
          title: "我的资源",
          titleEng: "/ My Resources",
          button: "添加",
          show: true,
        },
        {
          name: "数字藏品",
          icon: this.$ossRes("/manage/icon_nft.png"),
          iconActive: this.$ossRes("/manage/icon_nft_active.png"),
          iconActiveMobile: this.$ossRes(
            "/manage/icon_resource_active_mobile.png"
          ),
          value: "nft",
          title: "我的数字藏品",
          titleEng: "/ My NFT",
          button: "添加",
          show: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  created() {
    //新建频道权限
    this.$http.get("/channel-manage/is-white").then(({ data }) => {
      if (!data.is_white) {
        let channelObj = this.navList.find((i) => i.value === "channel");
        channelObj.show = false;
      }
    });

    wxShare({});
    if (!this.userInfo) {
      // 未登录
      this.$router.replace({
        name: routesData.index.name,
      });
    }
    if (this.$route.query && this.$route.query.tab) {
      this.navList.map((i, k) => {
        if (i.value == this.$route.query.tab) {
          this.currentNav = k;
        }
      });
    }
    window.addEventListener("resize", this.reload);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.reload);
  },
  mounted() {
    document.getElementById("app").scrollTop = 0;
  },
  methods: {
    reload() {
      location.reload();
    },
    getIcon(i, k) {
      return k == this.currentNav
        ? this.$utils.isMobile().mobile &&
          window.matchMedia("(orientation: portrait)").matches
          ? i.iconActiveMobile
          : i.iconActive
        : i.icon;
    },
    locateChange(e) {
      e &&
        this.navList.map((i, k) => {
          if (i.value == e) {
            this.navClick(i, k);
          }
        });
    },
    navClick(i, k) {
      this.currentNav = k;
      this.$router
        .push({
          name: routesConvert(routesModuleData.manage, true).name,
          query: {
            tab: this.navList[this.currentNav].value,
          },
        })
        .catch(() => {});
    },
    doAction() {
      const nav = this.navList[this.currentNav].value;
      if (nav == "activity") {
        this.$router.push({
          name: routesConvert(routesModuleData.activityCreate, true).name,
        });
      }
      if (nav == "channel") {
        this.$router.push({
          path: "/stage/channel/create",
        });
      } else if (nav == "resource") {
        this.$refs.resource.$emit("createOpen");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.index {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  -webkit-text-size-adjust: 100% !important;
  text-size-adjust: 100% !important;
  -moz-text-size-adjust: 100% !important;
  background: url(#{$PublicEnv}/activity_my/my_activity_bg_pc.png);
  background-size: 100% 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  .content {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    box-sizing: border-box;
    padding-top: 220px;
    overflow: hidden;
    .nav {
      flex-shrink: 0;
      width: 378px;
      height: 100%;
      box-sizing: border-box;
      padding-top: 230px;
      margin: 0px 0 0 60px;
      display: flex;
      flex-direction: column;
      .nav-item {
        width: 100%;
        height: 90px;
        display: flex;
        box-sizing: border-box;
        padding-left: 38px;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 24px;
        img {
          width: 48px;
          height: 48px;
          margin-right: 42px;
        }
        span {
          font-size: 40px;
          font-family: Source Han Sans CN-Normal, Source Han Sans CN;
          font-weight: 400;
          color: #999999;
        }
        &.active {
          background: #e7e5f8;
          border-radius: 10px 10px 10px 10px;
          span {
            color: rgba(51, 51, 51, 1);
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .info {
      flex: 1;
      display: flex;
      flex-direction: column;
      height: 100%;
      box-sizing: border-box;
      .title {
        padding-left: 94px;
        flex-shrink: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        padding-right: 140px;
        .left {
          flex-shrink: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            &:first-child {
              font-size: 56px;
              font-family: Source Han Sans CN-Medium, Source Han Sans CN;
              font-weight: 500;
              color: #252727;
              margin-right: 20px;
            }
            &:nth-child(2) {
              font-size: 40px;
              font-family: DIN-Bold, DIN;
              font-weight: bold;
              text-shadow: 0px 16px 64px rgba(0, 0, 0, 0.16);
              background: linear-gradient(
                270deg,
                #4e54ff 13%,
                #21b4c5 51%,
                #1285d1 91%
              );
              background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
        .right {
          flex-shrink: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          &.right-resource {
            // visibility: hidden;
            // user-select: none;
            // pointer-events: none;
          }
          &.right-nft {
            visibility: hidden;
          }
          .create-action {
            width: 310px;
            height: 119px;
            background: url(#{$PublicEnv}/activity_my/activity_create_bg.png);
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            padding-top: 14px;
            box-sizing: border-box;
            span {
              font-size: 36px;
              font-family: Source Han Sans CN-Medium, Source Han Sans CN;
              font-weight: 500;
              color: #ffffff;
            }
          }
        }
      }
      .container {
        flex: 1;
        width: 100%;
        box-sizing: border-box;
        padding: 100px 280px 0 0;
        padding-left: 94px;
        overflow-x: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 0 !important;
        }
      }
    }
  }
}
@media (orientation: portrait) {
  .index {
    display: block;
    padding-bottom: 0;
    .content {
      height: 100%;
      box-sizing: border-box;
      justify-content: flex-start;
      align-items: center;
      padding-top: 696px;
      flex-direction: column;
      display: flex;
      .nav {
        width: 100%;
        height: auto;
        padding-top: 0;
        margin: 0;
        flex-direction: row;
        padding: 0 102px;
        .nav-item {
          width: 1034px;
          height: 369px;
          border-radius: 113px;
          padding-left: 0;
          justify-content: center;
          align-items: center;
          margin-bottom: 0;
          background: rgba(255, 255, 255, 1);
          margin-right: 102px;
          img {
            width: 185px;
            height: 185px;
            margin-right: 51px;
          }
          span {
            font-size: 110px;
            color: rgba(153, 153, 153, 1);
          }
          &.active {
            background: rgba(112, 118, 255, 1);
            border-radius: 113px;
            span {
              color: rgba(255, 255, 255, 1);
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .info {
        flex: 1;
        // display: flex;
        // flex-direction: column;
        border-radius: 61px;
        margin-top: 205px;
        box-sizing: border-box;
        padding: 0 102px 100px 102px;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 1),
          rgba(255, 255, 255, 0)
        );
        box-shadow: 0px 15px 154px 0px rgba(0, 0, 0, 0.1);
        overflow-y: auto;
        .title {
          padding: 0 102px;
          .left {
            flex-direction: column;
            align-items: flex-start;
            span {
              &:first-child {
                font-size: 184px;
                font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                font-weight: 500;
                color: #252727;
                padding-top: 150px;
              }
              &:nth-child(2) {
                font-size: 195px;
                font-family: DIN-Bold, DIN;
                font-weight: bold;
                line-height: 228px;
                text-shadow: 0px 41px 164px rgba(0, 0, 0, 0.16);
                background: linear-gradient(
                  270deg,
                  #4e55ff 13%,
                  #21b4c5 51%,
                  #1285d1 91%
                );
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
            }
          }
          .right {
            flex-shrink: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            .create-action {
              width: 1240px;
              height: 476px;
              background: url(#{$PublicEnv}/activity_my/activity_create_bg.png);
              background-size: 100% 100%;
              display: flex;
              justify-content: center;
              padding-top: 28px;
              box-sizing: border-box;
              position: absolute;
              bottom: -400px;
              right: 0;
              span {
                font-size: 164px;
                font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                font-weight: 500;
                color: #ffffff;
              }
            }
          }
        }
        .container {
          flex: 1;
          width: 100%;
          box-sizing: border-box;
          margin-top: 200px;
          padding: 0;
          overflow-x: hidden;
          overflow-y: auto;
          overflow-x: unset;
          overflow-y: unset;
          overflow: unset;
          padding-bottom: 500px;
          &::-webkit-scrollbar {
            width: 0 !important;
          }
        }
      }
    }
  }
}
</style>
