var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.show)?_c('div',{staticClass:"list"},[_vm._l((_vm.list),function(i,k){return _c('div',{key:k,staticClass:"list-item cursor-pointer",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.goDetail(i)}}},[_c('div',{staticClass:"item-img"},[_c('img',{attrs:{"src":i.cover}}),_c('div',{staticClass:"item-img-bg"},[_c('span',{staticClass:"label"},[_vm._v("ACTIVITYD")]),_c('div',{class:`status ${
            i.status_txt == '进行中'
              ? 'red'
              : i.status_txt == '已结束'
              ? 'grey'
              : i.status_txt == '未开始'
              ? 'blue'
              : ''
          }`},[_c('span',[_vm._v(_vm._s(i.status_txt))])])])]),_c('div',{staticClass:"down"},[_c('p',{staticClass:"item-title"},[_vm._v(_vm._s(i.title))]),_c('div',{staticClass:"time"},[_c('div',{staticClass:"icon-time"}),_c('span',[_vm._v(_vm._s(i.start_time.substring(0, 16)))]),_c('span',[_vm._v("--")]),_c('span',[_vm._v(_vm._s(i.end_time.substring(0, 16)))])]),_c('div',{staticClass:"spot",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.spotClick(k)}}},[_c('div',{staticClass:"icon"})]),_c('div',{class:`activity-nav ${_vm.activityIndexNav == k ? 'active' : ''}`,on:{"mouseleave":_vm.activityIndexNavReset}},[_c('div',{staticClass:"nav-item cursor-pointer",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.activityEdit(i, k)}}},[_c('div',{staticClass:"icon-edit"}),_c('span',[_vm._v("编辑活动")])]),_c('div',{staticClass:"nav-item cursor-pointer",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.activityStatistics(i, k)}}},[_c('div',{staticClass:"icon-edit"}),_c('span',[_vm._v("数据统计")])]),_c('div',{class:`nav-item cursor-pointer ${
            i.status_txt == '已结束' ? 'non-active' : ''
          }`,on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.activityOver(i, k)}}},[_c('div',{staticClass:"icon-over"}),_c('span',[_vm._v("结束活动")])])])])])}),_c('list-empty',{attrs:{"show":_vm.list.length == 0,"tab":"暂无活动","full":true}})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }