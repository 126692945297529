<template>
  <div class="list" v-if="show">
    <div
      class="list-item cursor-pointer"
      v-for="(i, k) in list"
      :key="k"
      @click.stop.prevent="itemClick(i)"
    >
      <div class="item-close" @click.stop.prevent="itemDelete(i)"></div>
      <div :class="`item-img type-${i.ext}`">
        <img
          :src="
            i.ext == 'pdf'
              ? $ossRes('/manage/icon_pdf.png')
              : i.ext == 'mp3'
              ? $ossRes('/manage/icon_mp3.png')
              : i.preview_img
          "
        />
      </div>
      <div class="down">
        <p class="item-title">{{ decodeURIComponent(i.name) }}</p>
      </div>
    </div>
    <list-empty
      :show="list.length == 0"
      tab="暂无资源"
      :full="true"
    ></list-empty>
    <action-confirm
      :show="deleteShow"
      @close="deleteClose"
      @submit="deleteCallback"
    >
      <div class="action-confirm-tips">
        <span>是否确认删除⽂件？</span>
      </div>
    </action-confirm>
    <upload-confirm
      :show="createShow"
      @close="createClose"
      @success="getList"
    ></upload-confirm>
    <van-popup round :style="popStyle" v-model="videoShow" @closed="videoPause">
      <div
        :class="`pop-video-wrapper ${
          $utils.isMobile().mobile ? 'video-mobile' : 'video-pc'
        }`"
      >
        <video
          class="pop-video"
          ref="popvideo"
          :src="videoSrc"
          controls="controls"
          preload
          autoplay
          x5-playsinline="true"
          playsinline="true"
          webkit-playsinline="true"
        />
      </div>
    </van-popup>
  </div>
</template>
<script>
import { routesModuleData, routesConvert } from "@/router/index";
import listEmpty from "@/components/common/ListEmpty.vue";
import ActionConfirm from "@/components/common/ActionConfirm.vue";
import UploadConfirm from "@/components/upload/UploadConfirm.vue";
import { ImagePreview } from "vant";
export default {
  components: { listEmpty, ActionConfirm, UploadConfirm },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 999,
      list: [],
      activityIndexNav: -1,
      deleteShow: false,
      deleteCallback: () => {},
      createShow: false,
      isUploaded: false,
      maxSize: 104857600,
      chunkSize: 5242880,
      createUploadFile: "",
      createUploadFileType: "",
      createUploadUrl: "",
      createUploadPreviewImage: "",
      videoShow: false,
      videoSrc: "",
      popStyle: ` position: fixed; overflow-y: hidden;background: transparent;${
        this.$utils.isMobile().mobile
          ? "width:90%;"
          : "width:max-content;max-width: 80%;height:90%;"
      }`,
    };
  },
  watch: {
    show: {
      handler: function (newVal) {
        if (newVal) {
          this.getList();
        } else {
          this.list = [];
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$on("createOpen", this.createOpen);
    });
  },
  computed() {},
  methods: {
    videoPause() {
      if (this.$refs.popvideo) {
        this.$refs.popvideo.pause();
      }
    },
    createClose() {
      this.createShow = false;
    },
    createOpen() {
      this.createShow = true;
    },
    deleteClose() {
      this.deleteShow = false;
    },
    itemDelete(i) {
      this.deleteShow = true;
      this.deleteCallback = async () => {
        this.deleteClose();
        this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: "操作中...",
        });
        const result = await this.$http.post("/resource/del", {
          id: i.id,
        });
        if (result.code == 200) {
          this.$toast.success("操作成功");
          this.getList(true);
        } else {
          this.$toast.fail("操作失败：" + result.msg);
        }
      };
    },
    itemClick(i) {
      if (i.ext == "png" || i.ext == "jpg" || i.ext == "jpeg") {
        ImagePreview([i.url]);
      } else if (i.ext == "mp4" || i.ext == "mov") {
        this.videoSrc = i.url;
        this.videoShow = true;
      } else if (i.ext == "pdf") {
        window.open(i.url);
      }
    },
    spotClick(k) {
      this.activityIndexNav = k;
    },
    activityIndexNavReset() {
      this.activityIndexNav = -1;
    },
    async activityEdit(item) {
      this.activityIndexNavReset();
      this.$router.push({
        name: routesConvert(routesModuleData.activityCreate, true).name,
        query: { id: item.id },
      });
    },
    goDetail(item) {
      if (item.status_txt == "已结束") {
        this.$toast.fail("活动已结束");
        return;
      }
      this.$router.push({
        name: routesConvert(routesModuleData.detail, true).name,
        query: { id: item.id },
      });
    },
    async getList(noToast) {
      if (!noToast) {
        this.$toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: "加载中...",
        });
      }
      const result = await this.$http.get("/resource/list", {
        page: this.currentPage,
        per_page: this.perPage,
      });
      if (result.code == 200) {
        this.$toast.clear();
        if (result.data.data && result.data.data.length > 0) {
          if (this.currentPage === 1) {
            this.list = [...result.data.data];
          } else {
            this.list = [...this.list, ...result.data.data];
          }
        } else {
          this.list = [];
        }
      } else {
        this.$toast.fail("加载失败：" + result.msg);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.pop-video-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .pop-video {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}
.action-confirm-tips {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .icon-upload {
    width: 80px;
    height: 80px;
    background: url(#{$PublicEnv}/manage/icon_upload.png);
    background-size: 100% 100%;
  }
  .icon-upload-success {
    width: 80px;
    height: 80px;
    background: url(#{$PublicEnv}/manage/icon_upload_success.png);
    background-size: 100% 100%;
    margin-bottom: 6px;
  }
  span {
    font-size: 36px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    &.tips {
      font-size: 28px;
      font-family: Source Han Sans CN-Light, Source Han Sans CN;
      font-weight: 300;
      color: #999999;
      margin-top: 16px;
    }
  }
  p {
    font-size: 36px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    text-align: center;
    padding: 0 60px;
    &.tips {
      font-size: 28px;
      font-family: Source Han Sans CN-Light, Source Han Sans CN;
      font-weight: 300;
      color: #999999;
      margin-top: 16px;
    }
  }
}
.list {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  .list-item {
    width: 712px;
    height: 562px;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px 2px rgba(172, 174, 197, 59%);
    transition: all 0.3s ease-in-out;
    margin-bottom: 100px;
    margin-right: 60px;
    position: relative;
    &:nth-child(4n) {
      margin-right: 0;
    }
    .item-close {
      position: absolute;
      right: 16px;
      top: 16px;
      width: 52px;
      height: 52px;
      background: url(#{$PublicEnv}/manage/icon_close.png);
      background-size: 100% 100%;
      z-index: 1;
    }
    .item-img {
      flex-shrink: 0;
      width: 100%;
      height: 402px;
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(246, 246, 246, 1);
      img {
        max-width: 100%;
        // width: auto;
        max-height: 100%;
        // min-height: 100%;
      }
      &.type-mp4,
      &.type-mov {
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(246, 246, 246, 1);
        img {
          width: auto;
          height: auto;
          min-height: auto;
          max-width: 100%;
          max-height: 100%;
        }
        &::after {
          content: "";
          width: 76px;
          position: absolute;
          height: 76px;
          left: 50%;
          margin-left: -38px;
          top: 50%;
          margin-top: -38px;
          background: url(#{$PublicEnv}/manage/icon_video.png);
          background-size: 100% 100%;
        }
      }
      &.type-pdf {
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(246, 246, 246, 1);
        img {
          width: 200px;
          min-height: auto;
          height: auto;
        }
      }
      &.type-mp3 {
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(246, 246, 246, 1);
        img {
          width: 250px;
          min-height: auto;
          height: auto;
        }
      }
    }
    .down {
      flex: 1;
      width: 100%;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      padding-left: 36px;
      position: relative;
      .activity-nav {
        width: 320px;
        height: 246px;
        border-radius: 22px;
        background: linear-gradient(
          45deg,
          rgba(31, 39, 53, 1),
          rgba(51, 58, 72, 1)
        );
        visibility: hidden;
        opacity: 0;
        transition: all 0.4s ease-in-out;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        box-sizing: border-box;
        padding: 48px 0;
        z-index: 10;
        &.active {
          visibility: visible;
          top: -60px;
          opacity: 1;
        }
        .nav-item {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          transition: all 0.3s ease-in-out;
          &:first-child {
            background: linear-gradient(
              90deg,
              rgba(68, 86, 176, 1),
              rgba(36, 60, 115, 0) 80%
            );
          }
          height: 64px;
          box-sizing: border-box;
          box-sizing: border-box;
          padding: 22px 54px 12px 54px;
          span {
            margin-left: 14px;
            font-size: 30px;
            font-family: Source Han Sans CN-Normal, Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
          }
          &.non-active {
            // span {
            //   color: #999999;
            // }
          }
          .icon-edit {
            flex-shrink: 0;
            width: 56px;
            height: 56px;
            background: url(#{$PublicEnv}/activity_my/icon_edit.png);
            background-size: 100% 100%;
          }
          .icon-over {
            flex-shrink: 0;
            width: 56px;
            height: 56px;
            background: url(#{$PublicEnv}/activity_my/icon_over.png);
            background-size: 100% 100%;
          }
        }
      }
      .spot {
        position: absolute;
        right: 0;
        bottom: 0;
        padding-right: 40px;
        padding-bottom: 30px;
        .icon {
          width: 54px;
          height: 14px;
          background: url(#{$PublicEnv}/activity_my/spot.png);
          background-size: 100% 100%;
        }
      }
      .item-title {
        font-size: 40px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #333333;
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
      }
      .time {
        width: 100%;
        margin-top: 14px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .icon-time {
          width: 48px;
          height: 48px;
          background: url(#{$PublicEnv}/index/icon_time.png);
          background-size: 100% 100%;
          margin-right: 20px;
          flex-shrink: 0;
        }
        span {
          font-size: 32px;
          font-family: DIN-Regular, DIN;
          font-weight: 400;
          color: #666666;
          &:nth-child(2) {
            margin: 0 6px;
          }
        }
      }
    }
  }
}
@media (orientation: portrait) {
  .list {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    min-width: 3430px;
    .list-item {
      width: 3430px;
      height: 2598px;
      border-radius: 51px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-shadow: 0 20px 154px 0px rgba(173, 174, 198, 0.59);
      transition: all 0.3s ease-in-out;
      margin-bottom: 205px;
      margin-right: 0;
      overflow: auto;
      .item-close {
        position: absolute;
        right: 102px;
        top: 102px;
        width: 307px;
        height: 307px;
        background: url(#{$PublicEnv}/manage/icon_close.png);
        background-size: 100% 100%;
        z-index: 1;
      }
      .item-img {
        flex-shrink: 0;
        width: 100%;
        height: 2060px;
        position: relative;
        overflow: hidden;
        img {
          max-width: 100%;
          // width: auto;
          max-height: 100%;
          // min-height: 100%;
        }
        &.type-mp4,
        &.type-mov {
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgba(246, 246, 246, 1);
          img {
            width: auto;
            height: auto;
            min-height: auto;
            max-width: 100%;
            max-height: 100%;
          }
          &::after {
            content: "";
            width: 461px;
            position: absolute;
            height: 461px;
            left: 50%;
            margin-left: -230.5px;
            top: 50%;
            margin-top: -230.5px;
            background: url(#{$PublicEnv}/manage/icon_video.png);
            background-size: 100% 100%;
          }
        }
        &.type-pdf {
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgba(246, 246, 246, 1);
          img {
            width: 800px;
            min-height: auto;
            height: auto;
          }
        }
        &.type-mp3 {
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgba(246, 246, 246, 1);
          img {
            width: 850px;
            min-height: auto;
            height: auto;
          }
        }
      }
      .down {
        height: 538px;
        padding: 0 154px 0 154px;
        .item-title {
          font-size: 154px;
        }
      }
    }
  }
  .action-confirm-tips {
    .icon-upload {
      width: 420px;
      height: 420px;
    }
    .icon-upload-success {
      width: 420px;
      height: 420px;
      margin-bottom: 77px;
    }
    span {
      font-size: 184px;
      &.tips {
        font-size: 143px;
        margin-top: 128px;
      }
    }
    p {
      font-size: 184px;
      padding: 0 180px;
      &.tips {
        font-size: 143px;
        margin-top: 128px;
      }
    }
  }
}
</style>
