<template>
  <div v-if="show" class="m-view-channel-list">
    <action-confirm :show="showCs" @close="showCs = false" :needAction="false">
      <div class="action-confirm-tips">
        <img :src="csQrcode.url" />
        <span>请使用微信扫码联系客服</span>
      </div>
    </action-confirm>

    <popCs :show="showCs" v-if="showCs" @close="showCs = false"></popCs>
    <van-empty
      class="custom-image"
      :image="require('@/assets/img/no-permission.png')"
      description=""
      v-if="!hasPermission"
    >
      <p>
        <span>频道尚未开放，</span
        ><span class="link" @click="handleHelp">请联系客服</span>
      </p>
    </van-empty>
    <Table
      :data="tableData"
      :style="{ width: tableWidth }"
      v-if="hasPermission"
    >
      <TableColumn prop="logo" label="频道logo">
        <template slot-scope="scope">
          <img class="logo-img" :src="scope.row.logo" alt="" />
        </template>
      </TableColumn>
      <TableColumn prop="name" label="频道"> </TableColumn>
      <TableColumn prop="des" label="描述">
        <template slot-scope="scope">
          <div class="describe-column">
            <div v-html="scope.row.describe"></div>
          </div>
        </template>
      </TableColumn>
      <TableColumn label="操作">
        <template slot-scope="scope">
          <van-popover
            v-model="scope.row.showPopover"
            theme="dark"
            trigger="click"
            :actions="actions"
            v-if="isMobile"
            @select="
              (action) => {
                handleSelect(action, scope.row);
              }
            "
          >
            <template #reference>
              <van-button
                icon="more-o"
                type="primary"
                class="mobile-more-btn"
              />
            </template>
          </van-popover>
          <div v-else>
            <Button type="text" @click="editRow(scope.row)">编辑</Button>
            <Button type="text" style="color: red" @click="deleteRow(scope.row)"
              >删除</Button
            >
            <Button type="text" @click="preview(scope.row)">查看</Button>
          </div>
        </template>
      </TableColumn>
    </Table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Table, TableColumn, Button } from "element-ui";
import popCs from "@/components/PopCs.vue";
import ActionConfirm from "@/components/common/ActionConfirm.vue";
export default {
  components: { Table, TableColumn, Button, ActionConfirm },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
  data() {
    return {
      hasPermission: false,
      tableData: [],
      searchOption: {
        page: 1,
        per_page: 10,
      },
      showCs: false,
      tableWidth: "100%",
      showPopover: false,
      actions: [
        { text: "编辑", method: "editRow" },
        { text: "删除", method: "deleteRow" },
        { text: "查看", method: "preview" },
        { text: "统计", method: "dataCollect" },
      ],
    };
  },
  computed: {
    ...mapGetters(["csQrcode"]),
    isMobile() {
      return window.innerWidth <= 750;
    },
  },
  mounted() {
    if (window.innerWidth <= 750) {
      this.tableWidth = "90vw";
    }
  },
  async created() {
    const { data } = await this.$http.get("/channel-manage/is-white");
    this.hasPermission = !!data.is_white;
    if (!this.hasPermission) return;
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const { data } = await this.$http.get(
        "/channel-manage/list",
        this.searchOption
      );
      this.tableData = data?.data.map((item) => {
        return { ...item, showPopover: false };
      });
    },
    async deleteRow({ id }) {
      try {
        await this.$dialog.confirm({
          title: "提示",
          message: "此操作将永久删除该数据, 是否继续?",
        });
        this.$http.post("/channel-manage/del", { id }).then(() => {
          this.fetchData();
        });
      } catch (err) {}
    },
    editRow({ id }) {
      this.$router.push({
        path: "/stage/channel/edit",
        query: { id },
      });
    },
    preview({ id }) {
      this.$router.push({
        path: "/channel/view",
        query: { id },
      });
    },
    dataCollect({ id }) {
      this.$router.push({
        path: "/channel/data",
        query: { id },
      });
    },
    handleHelp() {
      this.showCs = true;
    },
    handleSelect({ method }, item) {
      this[method](item);
    },
  },
};
</script>

<style lang="scss" scoped>
.action-confirm-tips {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    width: 360px;
    height: 360px;
    margin-top: 160px;
    margin-bottom: 46px;
  }
  span {
    font-size: 36px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    &.tips {
      font-size: 28px;
      font-family: Source Han Sans CN-Light, Source Han Sans CN;
      font-weight: 300;
      color: #999999;
      margin-top: 16px;
    }
  }
}
.m-view-channel-list {
  .link {
    color: #2247cd;
    cursor: pointer;
  }
  .logo-img {
    width: 160px;
    object-fit: contain;
  }
}

@media screen and (max-width: 750px) {
  .m-view-channel-list {
    .link {
    }
    .logo-img {
      width: 10vw;
    }
    ::v-deep .el-table {
      .describe-column {
        max-height: 4em;
        overflow-y: auto;
      }
      .mobile-more-btn {
        background: transparent;
        border-radius: 50%;
        padding: 0;
        color: #797979;
        width: 5.6vw;
        height: 5.6vw;
        font-size: 5.6vw;
      }
    }
  }
}
</style>
