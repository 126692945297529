var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.show)?_c('div',{staticClass:"list"},[_vm._l((_vm.list),function(i,k){return _c('div',{key:k,staticClass:"list-item cursor-pointer",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.itemClick(i)}}},[_c('div',{staticClass:"item-close",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.itemDelete(i)}}}),_c('div',{class:`item-img type-${i.ext}`},[_c('img',{attrs:{"src":i.ext == 'pdf'
            ? _vm.$ossRes('/manage/icon_pdf.png')
            : i.ext == 'mp3'
            ? _vm.$ossRes('/manage/icon_mp3.png')
            : i.preview_img}})]),_c('div',{staticClass:"down"},[_c('p',{staticClass:"item-title"},[_vm._v(_vm._s(decodeURIComponent(i.name)))])])])}),_c('list-empty',{attrs:{"show":_vm.list.length == 0,"tab":"暂无资源","full":true}}),_c('action-confirm',{attrs:{"show":_vm.deleteShow},on:{"close":_vm.deleteClose,"submit":_vm.deleteCallback}},[_c('div',{staticClass:"action-confirm-tips"},[_c('span',[_vm._v("是否确认删除⽂件？")])])]),_c('upload-confirm',{attrs:{"show":_vm.createShow},on:{"close":_vm.createClose,"success":_vm.getList}}),_c('van-popup',{style:(_vm.popStyle),attrs:{"round":""},on:{"closed":_vm.videoPause},model:{value:(_vm.videoShow),callback:function ($$v) {_vm.videoShow=$$v},expression:"videoShow"}},[_c('div',{class:`pop-video-wrapper ${
        _vm.$utils.isMobile().mobile ? 'video-mobile' : 'video-pc'
      }`},[_c('video',{ref:"popvideo",staticClass:"pop-video",attrs:{"src":_vm.videoSrc,"controls":"controls","preload":"","autoplay":"","x5-playsinline":"true","playsinline":"true","webkit-playsinline":"true"}})])])],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }